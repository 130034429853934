import React, { useState, useEffect } from "react";
import Alert from "../../utils/alert";
import { formatDNI, removeZeros } from "../../utils/helpers";
import { getToday,formatDate } from "../../utils/date";
import { obtenerSolicitud } from "../../services/api/solicitud/index";
import { SaveLog } from "../../services/api/valida/index";

const FormComprobante = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [datos, setDatos] = useState({ 
        rut: "",
        nombre: "",
        fecha: "",
        numeroSolicitud: 0,
        modalidad: "",
        fechaSeleccion: ""
    });

    useEffect(async () => {
        setIsLoading(true);

        const obtener_solicitud = await obtenerSolicitud(localStorage.getItem("idPersona"));        
        const resultado  = obtener_solicitud.data.resultado;    
        let res = {
            Url: "https://turetiroconmodelo.cl/eld/comprobante",
            Method: "obtenerSolicitud",
            Endpoint:`/Solicitud/obtenerSolicitud?rut=${localStorage.getItem("idPersona")}`,
            Rut: JSON.stringify(localStorage.getItem("idPersona")),
            Request: JSON.stringify({ IdPersona : localStorage.getItem("idPersona")}),
            Response: JSON.stringify(obtener_solicitud)
        };
    
        await SaveLog(res);
        setDatos({
            rut: formatDNI(removeZeros(resultado.idPersona)),
            nombre: `${resultado.nombreAfi} ${resultado.apPaternoAfi} ${resultado.apMaternoAfi}`,
            fecha: formatDate(resultado.createDate),
            numeroSolicitud: resultado.folioSolicitud,
            modalidad: localStorage.getItem("mode"),
            fechaSeleccion: formatDate(getToday())
        });

        setIsLoading(false);

    }, []);

    return (
        <form>
            <div className="form-group">
                <p className="comprobante-label">RUT</p>
                <p className="comprobante-detalle">{datos.rut}</p>
            </div>
            <div className="form-group">
                <p className="comprobante-label">Nombre afiliado o pensionado</p>
                <p className="comprobante-detalle">{datos.nombre}</p>
            </div>
            <div className="form-group">
                <p className="comprobante-label">Fecha de solicitud</p>
                <p className="comprobante-detalle">{datos.fecha}</p>
            </div>
            <div className="form-group">
                <p className="comprobante-label">Número de solicitud</p>
                <p className="comprobante-detalle">{datos.numeroSolicitud}</p>
            </div>
            <div className="form-group">
                <p className="comprobante-label">Modalidad de pago</p>
                <p className="comprobante-detalle">{datos.modalidad}</p>
            </div>
            <div className="form-group">
                <p className="comprobante-label">Fecha de selección</p>
                <p className="comprobante-detalle">{datos.fechaSeleccion}</p>
            </div>
            <div className="form-group">
                <Alert image="success"
                    className="alert-custom-dark"
                    message="Tu selección ya ha sido ingresada. Te enviaremos una copia con el detalle a tu correo electrónico. " />
            </div>
        </form>
    );
};

export default FormComprobante;