import { ingresarDecisionPago } from "../../services/api/solicitud/index";
import Swal from "sweetalert2";
import { SaveLog } from "../../services/api/valida/index";
import { parseUF } from "../../utils/helpers";

const MySwal = Swal.mixin({
    customClass: {
        cancelButton: 'btn btn-cerrar',
        confirmButton: 'btn-custom btn-lg btn-custom-primary',
        title: 'font-weight-bold text-left',
        footer: 'swal-footer',
    },
    allowOutsideClick: false,
    buttonsStyling: false
});

export const handleSubmit = async ({values, dataForm, montosELD, montosRT, changeLoading}) => {
    changeLoading(true);

    let data = {
        seqBenefCalc: dataForm.seqBenefCalc,
        idcliente: dataForm.idcliente,
        numTramitePension: dataForm.numTramitePension,
        indSolPagoEld: '',
        indSolMaxEldRtAjuPbs: '',
        tipoAjusteMonto: '',
        valUFMonto: 0,
        mode: ""
    };

    if(values.radioButton === "1"){
        data.indSolPagoEld = "S";
        data.indSolMaxEldRtAjuPbs = "S";
        data.mode = "Excedente Libre Disposición Máximo";
        //data.tipoAjusteMonto = "";
    }

    if(values.radioButton === "2"){
        data.indSolPagoEld = "N";
        //data.tipoAjusteMonto = "";
        //data.indSolMaxEldRtAjuPbs = "N";
        data.mode = "Renta Temporal Máxima";
    }

    if(values.radioButton === "3"){

        let UFELD = parseInt(dataForm.ItemThreeUTM.ExcedenteLibreDisposicion.UF);
        let UFRT = parseInt(dataForm.ItemThreeUTM.RentaTemporal.UF);

        data.indSolPagoEld = "S";
        data.indSolMaxEldRtAjuPbs = "N";
        data.tipoAjusteMonto = "ELD";
        data.valUFMonto =  UFELD + UFRT;
        data.mode = "Excedente Libre Disposición";
    }

    if(values.radioButton === "4"){
        let UF = parseUF(montosELD.UF);
       
        data.indSolPagoEld = "S";
        data.indSolMaxEldRtAjuPbs = "N";
        data.tipoAjusteMonto = "ELD";
        data.valUFMonto = UF;
        data.mode = "Fijar el Monto del Excedente Libre Disposición";
    }

    if(values.radioButton === "5"){
        data.indSolPagoEld = "S";
        data.indSolMaxEldRtAjuPbs = "N";
        data.tipoAjusteMonto = "PEN";
        data.valUFMonto = montosRT.UF;
        data.mode = "Fijar el Monto de la Renta Temporal";
    }

    let ingresar_decision_pago = await ingresarDecisionPago(data);
    let res = {
        Url: "https://turetiroconmodelo.cl/eld/modalidadpago",
        Method: "ingresarDecisionPago",
        Endpoint: `/Solicitud/IngresarDecisionPago`,
        Rut: JSON.stringify(localStorage.getItem("idPersona")),
        Request: JSON.stringify({ Datos : data}),
        Response: JSON.stringify(ingresar_decision_pago)
    };

    await SaveLog(res);
    
    if(ingresar_decision_pago.data.ok){
        window.location ="/eld/resumen";
        localStorage.setItem("mode", data.mode);
    }
    else{
        console.log(ingresar_decision_pago);
    }

    changeLoading(false);

}