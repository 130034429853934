import {
  genericPost,
  genericGet,
  genericPut,
} from "../../../utils/genericApiCall";

export async function obtenerDecisionPago(idPersona) {
  const endpoint = `/Solicitud/TraerDecisionPago?idPersona=${idPersona}`;
  const message_info = "Obteniendo decision pago...";
  const message_error = "Error en la decision pago...";

  return await genericGet({
    url_endpoint: endpoint,
    message_info: message_info,
    message_error: message_error,
  });
}

export async function ingresarDecisionPago(body) {
  const url_endpoint = "/Solicitud/IngresarDecisionPago";
  const message_info = "Ingresando decision pago...";
  const message_error = "Error en ingresar decision pago...";

  return await genericPost({
    url_endpoint: url_endpoint,
    body: body,
    message_info: message_info,
    message_error: message_error,
  });
}

export async function obtenerSolicitud(idPersona) {
  const endpoint = `/Solicitud/obtenerSolicitud?rut=${idPersona}`;
  const message_info = "Obteniendo solicitud...";
  const message_error = "Error al obtener solicitud...";

  return await genericGet({
    url_endpoint: endpoint,
    message_info: message_info,
    message_error: message_error,
  });
}

export async function actualizarSolicitud(body) {
  const endpoint = `/Solicitud/actualizarSolicitud`;
  const message_info = "Actualizando solicitud...";
  const message_error = "Error al actualizar solicitud...";

  return await genericPut({
    url_endpoint: endpoint,
    body: body,
    message_info: message_info,
    message_error: message_error,
  });
}

export async function clearInformacionMandatario(idPersona) {
  let body = {
    rut: idPersona,
  };
  const endpoint = "/Solicitud/actualizarMandatario";
  const message_info = "Eliminar información de mandatario";
  const message_error = "Error al eliminar información de mandatario";

  return await genericPut({
    url_endpoint: endpoint,
    body: body,
    message_info: message_info,
    message_error: message_error,
  });
}

export async function obtenerDecisionPagoSaldos(idPersona, folio) {
  const endpoint = `/Solicitud/TraerSaldos?idPersona=${idPersona}&folio=${folio}`;
  const message_info = "Obteniendo decision pago...";
  const message_error = "Error en la decision pago...";

  return await genericGet({
    url_endpoint: endpoint,
    message_info: message_info,
    message_error: message_error,
  });
}
