import axios from "axios";
import Swal from "sweetalert2";
import configuration from "../../Configuration";
import {
  removeCodigoArea,
  cleanDigitos,
  convertirRutAId,
  cleanRut,
} from "../cleanInputMask";
import { convertDate } from "../../utils/date.js";

export const handleSubmitFormBeneficiario = ({
  fields,
  changeLoading,
  solicitud,
  history,
  idPersona,
  idCliente,
}) => {
  changeLoading(true);

  Swal.fire({
    title: "Confirmación de ingreso de benificiarios.",
    text: "Por favor, antes de continuar asegurate de haber ingresado correctamente a tus beneficiarios. ",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#72B500",
    cancelButtonColor: "#d33",
    confirmButtonText: "Continuar",
    cancelButtonText: `Volver a Beneficiarios`,
    allowOutsideClick: false,
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      if (fields.checkBeneficiario === false) {
        const beneficiarios = fields.beneficiarios;

        let fechaNueva = "";

        const conyuge = ["10", "11", "12", "13", "14", "15", "16"];
        const hijos_comunes = [
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
        ];
        const viveExpensas = ["20", "21"];
        const informante = localStorage.getItem("informante");
        let url_ingresar_beneficiarios = "";

        if (beneficiarios.length === 1) {
          url_ingresar_beneficiarios = `${configuration.API_URL}/Beneficiarios/IngresarBeneficiario`;
        } else {
          url_ingresar_beneficiarios = `${configuration.API_URL}/Beneficiarios/AgregarBeneficiarios`;
        }

        if (beneficiarios.length === 1) {
          fechaNueva = convertDate(beneficiarios[0].fechaNacimiento);

          let numgrupo = 0;

          conyuge.some((id) => id === beneficiarios[0].parentesco)
            ? (numgrupo += 1)
            : (numgrupo += 2);
          const vive = viveExpensas.some(
            (id) => id === beneficiarios[0].parentesco
          )
            ? "S"
            : "N";
          const id_hijos_comunes = hijos_comunes.some(
            (id) => id === beneficiarios[0].parentesco
          )
            ? "S"
            : "N";

          const idBeneficiario = cleanRut(beneficiarios[0].rut);
          const newIdBeneficiario = convertirRutAId(idBeneficiario);

          let celular = removeCodigoArea(
            cleanDigitos(beneficiarios[0].telefonoContacto)
          );
          let ciudad = "";
          let codComuna = 0;
          let codRegion = 0;
          let direccion = "";
          let depto = "";
          let email = beneficiarios[0].correoElectronico;

          if (informante === "A") {
            ciudad = solicitud.ciudadAfi;
            codComuna = parseInt(solicitud.codComunaAfi);
            codRegion = parseInt(solicitud.codRegionAfi);
            depto =
              solicitud.numeroDeptoAfi !== null
                ? ` depto. ${solicitud.numeroDeptoAfi}`
                : "";
            direccion =
              solicitud.nombreCalleAfi.substring(0, 30) +
              " " +
              (solicitud.numeroAfi.length > 5 ? "" : solicitud.numeroAfi) +
              depto;
          } else {
            ciudad = solicitud.ciudadMan;
            codComuna = parseInt(solicitud.codComunaMan);
            codRegion = parseInt(solicitud.codRegionMan);
            direccion = solicitud.direccionMan;
          }

          const body = {
            folioSolicitud: localStorage.getItem("numFolio"),
            idPersona: idPersona,
            idCliente: parseInt(idCliente),
            idBeneficiario: newIdBeneficiario,
            nombre: beneficiarios[0].nombres,
            apPaterno: beneficiarios[0].apellidoPaterno,
            apMaterno: beneficiarios[0].apellidoMaterno,
            celular: removeCodigoArea(cleanDigitos(celular)),
            ciudad: ciudad,
            codComuna: codComuna,
            codParentesco: parseInt(beneficiarios[0].parentesco),
            codRegion: codRegion,
            direccion: direccion,
            email: email,
            estadoCivil: "S",
            fecMatrimonio: "2021-03-27T00:43:19.973Z",
            fecNacimiento: fechaNueva,
            indHijosComunes: id_hijos_comunes,
            indNuevoBeneficiario:
              beneficiarios[0].indNuevoBeneficiario === "N" ? "N" : "S",
            indViveExpensas: vive,
            numGrupoFamiliar: numgrupo,
            seqBeneficiario: 99,
            sexo: beneficiarios[0].genero === "0" ? "M" : "F",
            telefono: "",
            tipoVigencia: "N",
          };

          axios
            .post(url_ingresar_beneficiarios, body, {
              headers: configuration.JSON_HEADER,
            })
            .then((response) => {
              Swal.fire({
                title: "Registro Exitoso.",
                text: "El beneficiario se registro correctamente. ",
                icon: "success",
                confirmButtonColor: "#72B500",
                confirmButtonText: "Continuar",
                allowOutsideClick: false,
              }).then((result) => {
                history.push("/cuentas");
              });
            })
            .catch((e) => {
              console.error(e);
            });
        } else {
          const body_beneficiario = [];

          beneficiarios.forEach(function (beneficiario) {
            fechaNueva = convertDate(beneficiario.fechaNacimiento);

            let numgrupo = 0;

            conyuge.some((id) => id === beneficiario.parentesco)
              ? (numgrupo += 1)
              : (numgrupo += 2);

            const vive = viveExpensas.some(
              (id) => id === beneficiario.parentesco
            )
              ? "S"
              : "N";
            const id_hijos_comunes = hijos_comunes.some(
              (id) => id === beneficiario.parentesco
            )
              ? "S"
              : "N";

            const idBeneficiario = cleanRut(beneficiario.rut);
            const newIdBeneficiario = convertirRutAId(idBeneficiario);

            let celular = cleanDigitos(beneficiario.telefonoContacto);
            let ciudad = "";
            let codComuna = 0;
            let codRegion = 0;
            let direccion = "";
            let depto = "";
            let email = beneficiario.correoElectronico;

            if (informante === "A") {
              ciudad = solicitud.ciudadAfi;
              codComuna = parseInt(solicitud.codComunaAfi);
              codRegion = parseInt(solicitud.codRegionAfi);
              depto =
                solicitud.numeroDeptoAfi !== null
                  ? ` depto. ${solicitud.numeroDeptoAfi}`
                  : "";
              direccion =
                solicitud.nombreCalleAfi.substring(0, 30) +
                " " +
                (solicitud.numeroAfi.length > 5 ? "" : solicitud.numeroAfi) +
                depto;
            } else {
              ciudad = solicitud.ciudadMan;
              codComuna = parseInt(solicitud.codComunaMan);
              codRegion = parseInt(solicitud.codRegionMan);
              direccion = solicitud.direccionMan;
            }

            const body = {
              folioSolicitud: localStorage.getItem("numFolio"),
              idPersona: idPersona,
              idCliente: parseInt(idCliente),
              idBeneficiario: newIdBeneficiario,
              nombre: beneficiario.nombres,
              apPaterno: beneficiario.apellidoPaterno,
              apMaterno: beneficiario.apellidoMaterno,
              celular: celular,
              ciudad: ciudad,
              codComuna: codComuna,
              codParentesco: parseInt(beneficiario.parentesco),
              codRegion: codRegion,
              direccion: direccion,
              email: email,
              estadoCivil: "S",
              fecMatrimonio: "2021-03-27T00:43:19.973Z",
              fecNacimiento: fechaNueva,
              indHijosComunes: id_hijos_comunes,
              indNuevoBeneficiario:
                beneficiario.indNuevoBeneficiario === "N" ? "N" : "S",
              indViveExpensas: vive,
              numGrupoFamiliar: numgrupo,
              seqBeneficiario: 99,
              sexo: beneficiario.genero === "0" ? "M" : "F",
              telefono: "",
              tipoVigencia: "N",
            };

            body_beneficiario.push(body);
          });

          axios
            .post(url_ingresar_beneficiarios, body_beneficiario, {
              headers: configuration.JSON_HEADER,
            })
            .then((response) => {
              if (response.status === 200) {
                Swal.fire({
                  title: "Registro Exitoso.",
                  text: "Los beneficiarios se registraron correctamente. ",
                  icon: "success",
                  confirmButtonColor: "#72B500",
                  confirmButtonText: "Continuar",
                  allowOutsideClick: false,
                }).then((result) => {
                  history.push("/cuentas");
                });
              }
            })
            .catch((e) => {
              console.error(e);
            });
        }
      } else {
        history.push("/cuentas");
      }
    }
  });

  changeLoading(false);
};
