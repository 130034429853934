import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { getToday, formatDate } from "../../utils/date";
import { formatDNI, removeZeros } from "../../utils/helpers";
import { obtenerSolicitud } from "../../services/api/solicitud/index";
import { handleSubmit } from "../../utils/handleSubmit/formResumenELD";
import { SaveLog } from "../../services/api/valida/index";

const FormResumen = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [datos, setDatos] = useState({
        rut: "",
        nombre: "",
        fecha: "",
        numeroSolicitud: 0,
        modalidad: "",
        fechaSeleccion: ""
    });

    useEffect(async () => {
        const idPersona =localStorage.getItem("idPersona")
        const obtener_solicitud = await obtenerSolicitud(idPersona);
        const resultado  = obtener_solicitud.data.resultado;
        let res = {
            Url: "https://turetiroconmodelo.cl/eld/resumen",
            Method: "obtenerSolicitud",
            Endpoint:`/Solicitud/obtenerSolicitud?rut=${idPersona}`,
            Rut: JSON.stringify(localStorage.getItem("idPersona")),
            Request: JSON.stringify({ IdPersona : idPersona}),
            Response: JSON.stringify(obtener_solicitud)
        };
    
        await SaveLog(res);
            setDatos({
                rut: formatDNI(removeZeros(resultado.idPersona)),
                nombre: `${resultado.nombreAfi} ${resultado.apPaternoAfi} ${resultado.apMaternoAfi}`,
                fecha: formatDate(resultado.createDate),
                numeroSolicitud: resultado.folioSolicitud,
                modalidad: localStorage.getItem("mode"),
                fechaSeleccion: formatDate(getToday())
            });
    }, []);

    const onSubmit = () => {
        handleSubmit({
            changeLoading: setIsLoading            
        });
    }

    return (
        <form>
            <div className="form-group">
                <p className="comprobante-label">RUT</p>
                <p className="comprobante-detalle">{datos.rut}</p>
            </div>
            <div className="form-group">
                <p className="comprobante-label">Nombre afiliado o pensionado</p>
                <p className="comprobante-detalle">{datos.nombre}</p>
            </div>
            <div className="form-group">
                <p className="comprobante-label">Fecha de solicitud</p>
                <p className="comprobante-detalle">{datos.fecha}</p>
            </div>
            <div className="form-group">
                <p className="comprobante-label">Número de solicitud</p>
                <p className="comprobante-detalle">{datos.numeroSolicitud}</p>
            </div>
            <div className="form-group">
                <p className="comprobante-label">Modalidad de pago</p>
                <p className="comprobante-detalle">{datos.modalidad}</p>
            </div>
            <div className="form-group">
                <p className="comprobante-label">Fecha de selección</p>
                <p className="comprobante-detalle">{datos.fechaSeleccion}</p>
            </div>
            <div className="mt-4">
                <div className="justify-content-center d-flex">
                    {isLoading
                        ? <>
                            <button type="button" className="btn-custom btn-lg btn-custom-primary" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                            </button>
                        </>
                        :
                        <button type="button" id="continuar" className="btn-custom btn-lg btn-custom-primary" onClick={onSubmit}>
                            Confirmar selección
                        </button>
                    }
                </div>
            </div>
            <div className="mt-1">
                <div className="justify-content-center d-flex">
                    <a className="volver" onClick={()=>{ window.location = "eld/ModalidadPago"; }}>Volver al paso anterior</a>
                </div>
            </div>
        </form>
    );
};

export default FormResumen;